import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1076.000000 1008.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1008.000000) scale(0.100000,-0.100000)">


<path d="M5095 9814 c-16 -2 -73 -9 -125 -15 -308 -34 -657 -126 -955 -249
-342 -142 -762 -411 -1030 -659 -146 -135 -331 -335 -452 -491 -66 -84 -248
-359 -257 -386 -9 -29 5 -30 229 -24 l180 5 76 102 c209 281 418 496 664 684
504 385 1077 607 1700 659 1008 84 2005 -326 2684 -1105 218 -250 401 -533
535 -828 l36 -78 30 22 c41 31 139 32 194 3 78 -40 113 -127 91 -222 -16 -66
-49 -102 -172 -187 -2 -1 10 -55 26 -121 119 -485 140 -945 66 -1419 -97 -620
-360 -1203 -759 -1680 -77 -93 -330 -343 -436 -431 -52 -44 -138 -109 -190
-145 -96 -67 -380 -242 -435 -269 -16 -8 -36 -21 -43 -27 -7 -7 -21 -13 -29
-13 -9 0 -13 -3 -10 -7 4 -3 -1 -15 -10 -26 -10 -10 -16 -21 -13 -24 3 -3 -38
-34 -90 -69 -52 -35 -107 -75 -122 -88 -15 -13 -41 -27 -57 -31 -17 -4 -39
-17 -50 -31 -25 -32 -57 -31 -65 1 -4 14 -4 30 -1 35 11 18 12 70 1 70 -6 0
-4 5 4 10 24 16 -24 6 -247 -50 l-43 -11 0 -149 c0 -122 3 -151 16 -164 28
-29 68 -22 359 58 190 52 481 166 625 246 19 10 60 31 90 46 48 25 199 116
269 163 13 9 37 25 55 36 181 114 535 440 712 656 195 239 365 504 491 769
538 1124 501 2450 -100 3545 -438 798 -1171 1419 -2017 1709 -252 86 -559 152
-815 176 -101 9 -539 12 -610 4z"/>
<path d="M5115 9280 c-414 -42 -756 -143 -1120 -328 -285 -146 -533 -328 -776
-571 -183 -183 -403 -461 -472 -598 l-17 -31 405 -2 c223 0 416 -5 430 -10 27
-10 64 -49 195 -205 50 -60 97 -114 104 -120 15 -13 145 -164 251 -293 11 -13
34 -40 52 -60 31 -36 135 -159 236 -277 28 -33 59 -69 69 -80 36 -39 74 -84
108 -126 19 -24 53 -64 75 -89 69 -77 139 -160 161 -191 11 -16 53 -63 92
-104 39 -41 84 -94 100 -117 17 -24 54 -69 83 -101 30 -31 80 -87 111 -125 32
-37 94 -110 139 -162 44 -52 97 -113 118 -135 21 -22 42 -49 48 -59 6 -10 38
-48 71 -85 34 -36 91 -102 128 -146 93 -113 164 -185 179 -185 22 0 43 38 76
139 18 53 40 107 50 120 18 23 17 47 -1 36 -14 -9 -63 71 -56 92 4 14 -3 23
-31 38 -39 21 -86 65 -143 134 -19 23 -52 61 -73 84 -22 23 -51 58 -65 77 -15
19 -35 41 -45 48 -9 7 -43 46 -74 85 -59 74 -286 339 -336 392 -15 17 -42 50
-60 73 -18 24 -67 83 -109 130 -43 48 -78 93 -78 100 0 6 -4 12 -9 12 -12 0
-175 210 -222 287 -20 32 -40 69 -44 83 -4 14 -16 36 -26 49 -11 13 -19 30
-19 38 0 7 -7 24 -15 36 -8 13 -25 59 -37 103 -19 65 -23 110 -26 259 -4 199
11 315 53 410 99 225 191 354 330 459 125 96 241 158 338 181 203 49 346 57
512 27 117 -22 137 -31 168 -77 23 -33 88 -85 107 -85 6 0 10 -3 10 -7 0 -5
21 -21 48 -38 65 -40 112 -78 112 -90 0 -5 18 -30 40 -55 22 -25 40 -51 40
-58 0 -8 12 -26 28 -42 31 -31 52 -64 52 -81 0 -6 9 -22 20 -36 11 -14 20 -34
20 -43 0 -10 6 -23 14 -29 32 -26 74 -302 62 -402 -11 -87 -4 -84 -183 -83
-87 1 -180 2 -208 4 -92 4 -137 -10 -170 -54 -11 -14 -27 -26 -37 -26 -20 0
-25 25 -31 142 -9 155 -104 273 -240 299 -126 23 -231 -37 -299 -170 -30 -58
-38 -144 -18 -179 5 -9 15 -28 20 -42 13 -32 56 -106 95 -162 24 -36 246 -301
265 -318 9 -8 128 -148 194 -228 53 -65 62 -72 95 -72 27 0 45 8 70 30 18 17
60 49 94 72 34 23 82 58 107 78 40 33 100 76 165 120 50 34 221 159 280 204
57 44 208 155 348 255 34 25 78 56 97 70 19 14 57 41 83 59 26 18 56 41 66 51
25 25 129 94 164 109 38 16 100 15 142 -2 46 -20 95 -97 95 -152 0 -46 -41
-120 -85 -150 -14 -11 -44 -34 -66 -51 -23 -18 -45 -33 -50 -33 -4 0 -19 -9
-31 -21 -13 -11 -56 -45 -97 -76 -41 -30 -79 -60 -85 -65 -6 -5 -74 -54 -151
-109 -77 -55 -148 -106 -157 -114 -9 -7 -26 -19 -38 -25 -23 -12 -40 -25 -99
-76 -20 -16 -63 -48 -96 -69 -79 -52 -229 -165 -233 -176 -2 -5 -8 -9 -13 -9
-13 0 -187 -127 -231 -169 -32 -30 -38 -41 -37 -76 1 -68 15 -132 32 -150 9
-9 17 -23 17 -30 0 -8 6 -18 13 -22 6 -4 20 -25 29 -45 10 -21 21 -38 26 -38
10 0 105 72 155 116 20 19 43 34 50 34 8 0 24 11 37 25 12 13 54 45 93 71 39
27 79 56 89 66 10 10 23 18 28 18 6 0 18 8 28 18 25 24 145 112 201 147 9 6
30 21 48 35 18 14 49 38 70 53 21 16 105 79 187 140 82 62 162 120 177 129 15
9 59 41 98 70 39 29 85 63 103 76 111 78 146 103 174 126 18 14 34 26 37 26 4
0 17 10 30 23 52 48 117 88 151 94 57 9 126 -6 154 -34 l25 -25 -7 27 c-40
161 -268 573 -437 791 -196 254 -463 512 -701 678 -413 287 -861 462 -1358
531 -137 19 -526 28 -655 15z"/>
<path d="M2200 7880 c-30 -42 -149 -303 -203 -447 -173 -454 -248 -875 -248
-1384 1 -476 61 -836 215 -1289 102 -298 300 -680 490 -945 44 -61 152 -201
166 -215 3 -3 34 -39 69 -80 176 -207 452 -450 701 -617 419 -282 911 -473
1395 -541 312 -45 311 -45 615 -39 162 3 310 9 329 14 31 8 32 9 16 23 -15 12
-69 16 -284 20 l-266 5 -105 38 c-58 21 -143 52 -190 69 -138 50 -338 169
-470 279 -31 26 -95 58 -195 99 -524 212 -936 503 -1294 915 -405 466 -676
1036 -785 1653 -123 693 -35 1404 252 2053 29 65 52 124 52 133 0 17 -3 21
-105 140 -38 45 -81 96 -95 114 -14 17 -28 32 -32 32 -4 0 -16 -14 -28 -30z"/>
<path d="M2585 7488 c-398 -837 -429 -1807 -83 -2678 94 -238 260 -534 414
-740 104 -138 204 -250 359 -400 437 -423 976 -697 1595 -810 107 -20 341 -39
330 -28 -3 3 -75 29 -160 58 -85 29 -166 59 -179 67 -13 9 -54 31 -90 50 -36
19 -77 46 -92 59 -14 13 -29 24 -33 24 -17 0 -152 116 -246 210 -159 159 -288
358 -355 550 -15 41 -30 84 -34 95 -60 153 -91 372 -91 643 0 192 0 193 25
218 l25 25 349 -3 c337 -3 351 -4 365 -23 12 -16 16 -54 17 -175 2 -204 12
-342 32 -410 24 -85 94 -229 135 -280 102 -125 249 -250 294 -250 37 0 39 28
36 499 l-3 459 -34 48 c-19 27 -40 56 -48 64 -7 8 -49 58 -93 110 -44 52 -98
115 -119 140 -22 25 -55 63 -73 85 -18 22 -41 46 -51 54 -10 7 -27 27 -37 43
-10 16 -40 55 -66 85 -27 30 -72 80 -99 111 -52 59 -137 158 -171 201 -10 13
-34 40 -51 60 -18 19 -33 38 -33 42 0 4 -9 10 -19 14 -11 3 -23 14 -26 24 -3
10 -19 33 -36 52 -17 19 -47 54 -67 78 -21 24 -52 60 -71 80 -18 20 -45 52
-60 71 -14 19 -35 44 -47 55 -11 11 -52 58 -90 105 -38 47 -92 110 -120 140
-72 76 -104 116 -104 128 0 6 -12 20 -26 31 -14 11 -37 36 -52 55 -28 36 -222
267 -232 276 -3 3 -43 50 -89 105 -47 55 -100 116 -120 135 -20 19 -43 45 -51
56 -30 44 -176 220 -210 254 -19 19 -61 67 -92 105 -74 91 -142 165 -152 165
-4 0 -36 -60 -71 -132z"/>
<path d="M8307 7124 c-3 -10 -8 -34 -12 -52 -8 -37 -42 -75 -117 -125 -29 -20
-73 -52 -98 -71 -25 -19 -96 -72 -159 -118 -62 -46 -129 -94 -147 -108 -19
-14 -53 -38 -76 -55 -23 -16 -91 -66 -150 -110 -59 -44 -133 -98 -164 -120
-31 -22 -62 -45 -68 -50 -6 -6 -36 -27 -66 -47 -30 -20 -64 -46 -76 -56 -11
-11 -34 -27 -50 -35 -16 -8 -42 -29 -58 -46 -17 -17 -34 -31 -39 -31 -5 0 -24
-12 -42 -27 -18 -16 -50 -39 -70 -53 -20 -14 -54 -38 -75 -55 -21 -16 -62 -46
-90 -65 -85 -58 -150 -111 -160 -131 -8 -14 -6 -22 9 -36 10 -10 25 -31 34
-48 8 -16 29 -48 46 -69 18 -22 39 -55 48 -73 9 -19 23 -33 33 -33 12 0 27
-19 45 -57 14 -32 44 -85 66 -119 21 -33 39 -64 39 -68 0 -4 9 -19 20 -33 11
-14 20 -32 20 -40 0 -7 16 -46 35 -85 34 -68 42 -103 44 -192 1 -22 4 -36 8
-33 18 19 44 -94 62 -273 12 -117 -2 -368 -23 -409 -9 -17 -16 -32 -15 -33 5
-10 -16 -115 -35 -173 -16 -47 -28 -69 -37 -67 -8 2 -11 0 -7 -3 4 -4 3 -16
-3 -27 -6 -11 -11 -34 -11 -51 -1 -18 -3 -26 -5 -19 -5 13 -24 17 -21 5 9 -32
-37 -140 -64 -151 -4 -2 -8 -8 -8 -13 0 -5 -8 -21 -17 -36 -10 -14 -18 -33
-20 -42 -1 -8 -22 -34 -47 -56 -25 -22 -46 -46 -46 -52 0 -7 -26 -39 -59 -73
-40 -41 -64 -58 -75 -54 -12 5 -16 0 -16 -21 0 -20 -4 -26 -15 -21 -10 4 -15
-1 -15 -14 0 -11 -4 -20 -10 -20 -5 0 -10 -6 -10 -13 0 -7 -32 -33 -71 -57
-39 -25 -85 -57 -101 -72 -17 -16 -36 -28 -42 -28 -6 0 -29 -12 -51 -27 -80
-55 -74 -81 -77 359 -4 489 -5 467 38 496 26 17 34 29 34 51 0 20 9 36 25 49
14 11 25 22 25 26 0 3 14 36 31 73 17 38 31 78 31 91 0 12 4 25 8 27 5 3 6 14
3 25 -3 11 2 41 10 67 20 61 20 239 1 308 -8 27 -17 61 -20 75 -2 14 -11 30
-18 35 -7 6 -16 40 -20 78 -4 37 -11 67 -16 67 -5 0 -11 9 -15 19 -3 10 -18
22 -32 26 -34 8 -50 -8 -106 -110 -36 -65 -52 -83 -87 -102 -24 -13 -57 -23
-75 -23 -70 0 -65 64 -63 -942 l3 -905 80 17 c287 60 628 195 894 355 684 410
1190 1064 1426 1842 186 612 190 1285 10 1903 -42 144 -50 162 -58 134z"/>
<path d="M2457 583 c-53 -4 -56 -5 -47 -24 6 -11 21 -19 35 -19 30 0 30 -6 6
-121 -18 -88 -57 -161 -103 -194 -69 -48 -85 20 -42 183 5 18 2 22 -19 22 -19
0 -26 -7 -32 -27 -9 -38 -61 -128 -98 -169 -18 -21 -38 -34 -53 -34 -21 0 -24
5 -24 38 0 21 7 64 15 97 17 65 14 85 -12 85 -15 0 -22 -16 -36 -84 -9 -46
-17 -97 -17 -113 0 -34 35 -77 57 -69 8 3 19 6 23 6 14 0 65 53 96 101 l29 43
3 -50 c6 -95 55 -110 132 -38 43 40 50 44 50 26 0 -12 13 -36 29 -54 25 -28
36 -33 71 -32 69 2 127 59 137 132 6 42 6 42 48 42 24 0 50 2 57 5 20 8 65
-85 46 -96 -81 -46 -104 -80 -71 -104 29 -23 78 -18 112 11 25 21 31 34 31 64
0 31 6 43 30 60 28 20 35 19 31 -4 -4 -21 23 -78 46 -96 28 -22 76 -26 118
-10 15 6 53 37 86 70 l59 59 -6 -63 c-4 -40 -3 -66 4 -70 15 -9 42 4 42 20 0
24 50 116 88 162 31 39 38 43 67 38 22 -5 36 -2 45 9 18 22 5 45 -27 45 -31 0
-109 -75 -142 -137 -18 -34 -20 -35 -20 -13 0 14 4 45 10 69 11 49 2 67 -29
57 -13 -4 -18 -13 -15 -24 6 -24 -75 -133 -124 -166 -82 -56 -160 -18 -150 73
8 74 72 135 117 111 11 -6 20 -17 20 -25 0 -18 12 -19 28 -3 17 17 15 45 -4
52 -28 11 -84 6 -113 -9 -16 -8 -44 -32 -62 -53 -42 -47 -105 -96 -117 -88 -5
3 -17 23 -27 46 -17 37 -17 41 -2 58 21 23 22 51 2 59 -18 6 -54 -23 -66 -54
-7 -20 -16 -23 -57 -23 -39 0 -55 5 -77 26 -33 31 -75 48 -87 36 -14 -14 2
-40 38 -61 29 -17 34 -26 34 -56 0 -20 -6 -51 -14 -70 -34 -81 -136 -70 -136
16 0 14 11 80 24 145 35 174 38 194 29 192 -4 -1 -34 -3 -66 -5z m383 -386 c0
-32 -33 -61 -59 -52 -28 9 -26 21 5 50 34 31 54 32 54 2z"/>
<path d="M7713 568 c-39 -28 -70 -81 -79 -134 -5 -34 -11 -42 -37 -50 -41 -12
-49 -37 -11 -32 21 2 28 -1 27 -12 -1 -8 -11 -64 -22 -125 -28 -145 -27 -155
4 -155 23 0 25 4 26 43 0 23 10 89 22 147 l23 105 68 3 68 3 -6 -33 c-17 -98
-18 -120 -6 -143 29 -55 91 -35 174 56 l58 64 -6 -71 c-5 -54 -4 -73 6 -76 20
-8 38 2 38 19 0 9 16 46 37 82 79 142 115 167 127 85 3 -26 6 -68 6 -93 0 -52
21 -91 50 -91 11 0 35 14 55 31 33 29 37 30 54 15 40 -36 120 -6 166 62 l25
37 0 -41 c0 -47 -10 -58 -89 -100 -68 -37 -111 -76 -111 -103 0 -59 95 -82
161 -38 41 27 79 98 79 147 0 33 5 40 53 73 52 36 74 46 63 29 -9 -15 13 -78
34 -97 47 -42 148 -12 242 73 l40 36 -7 -58 c-6 -43 -4 -60 5 -66 18 -11 40
-1 40 17 0 25 62 134 97 170 26 28 38 34 65 31 26 -2 35 2 43 20 5 12 18 22
27 22 21 0 21 1 3 -75 -8 -33 -15 -75 -15 -93 0 -44 24 -88 51 -95 30 -8 81
15 107 48 32 40 27 51 -9 21 -34 -29 -70 -34 -89 -11 -14 17 -9 100 10 168
l11 37 83 0 c62 0 87 4 96 15 7 8 18 15 26 15 16 0 87 60 80 68 -3 2 -17 -3
-30 -12 -14 -9 -30 -16 -36 -16 -5 0 -19 -9 -30 -20 -16 -16 -33 -20 -95 -20
-82 0 -86 4 -60 54 9 16 13 31 10 34 -12 13 -57 -21 -71 -53 -11 -28 -20 -35
-42 -35 -65 0 -155 -71 -202 -160 -22 -41 -25 -23 -8 49 13 57 5 74 -28 65
-15 -4 -20 -12 -17 -28 4 -17 -10 -39 -53 -85 -62 -68 -116 -101 -163 -101
-66 0 -84 70 -21 81 100 16 163 96 112 142 -41 37 -135 11 -175 -48 -14 -20
-49 -55 -79 -77 -52 -39 -53 -40 -56 -17 -4 20 11 117 24 162 7 21 -34 24 -42
3 -4 -11 -18 -13 -60 -9 -77 8 -121 -23 -165 -114 -31 -65 -69 -113 -90 -113
-17 0 -29 50 -29 124 0 58 -4 76 -18 89 -41 37 -95 4 -149 -93 -15 -28 -30
-50 -33 -50 -3 0 -3 32 -2 71 4 69 3 71 -19 67 -18 -2 -24 -10 -26 -31 -3 -41
-90 -149 -141 -176 -13 -7 -31 -10 -39 -7 -16 7 -16 31 3 154 l7 42 -91 0 -90
0 14 62 c20 83 37 108 75 108 17 0 32 -6 35 -15 8 -20 20 -19 28 1 17 45 -58
59 -111 22z m846 -169 c38 -13 39 -31 4 -78 -41 -55 -84 -91 -110 -91 -20 0
-23 5 -23 40 0 49 20 91 56 119 31 24 31 24 73 10z m346 -29 c0 -28 -6 -38
-34 -57 -35 -24 -81 -31 -81 -13 0 20 40 89 57 99 10 6 27 10 38 8 16 -1 20
-9 20 -37z m-345 -283 c-14 -48 -65 -77 -105 -59 -14 6 -25 20 -25 30 0 22 46
68 97 97 l38 21 3 -25 c2 -14 -1 -43 -8 -64z"/>
<path d="M4123 530 c-12 -5 -27 -25 -34 -44 -11 -31 -17 -36 -45 -36 -17 0
-37 -7 -44 -15 -11 -13 -7 -15 27 -15 l40 0 -18 -77 c-23 -95 -23 -106 -3
-148 25 -54 78 -53 137 3 38 37 36 61 -4 28 -37 -32 -74 -34 -90 -5 -12 23 -8
67 13 152 l12 47 76 0 c81 0 123 15 183 64 34 29 31 47 -5 22 -12 -9 -30 -16
-40 -16 -10 0 -18 -6 -18 -14 0 -18 -32 -26 -112 -26 -73 0 -79 5 -53 50 24
41 20 47 -22 30z"/>
<path d="M5858 533 c-9 -2 -25 -22 -34 -43 -15 -33 -22 -39 -52 -42 -49 -4
-55 -28 -8 -28 35 0 36 -1 30 -27 -18 -78 -104 -177 -148 -171 -19 3 -21 10
-21 73 0 39 4 85 8 103 8 28 6 32 -12 32 -11 0 -23 -5 -26 -10 -4 -7 -12 -7
-25 0 -25 13 -86 13 -113 -1 -57 -30 -97 -102 -97 -177 0 -86 48 -112 110 -58
42 37 45 40 70 81 l25 40 5 -54 c6 -51 25 -81 55 -81 15 0 97 53 114 73 20 25
31 21 31 -12 0 -39 30 -73 63 -73 39 -1 107 47 107 74 0 21 -1 21 -28 -5 -31
-30 -76 -36 -86 -11 -10 26 -6 96 7 149 l13 50 85 5 c80 5 109 12 109 28 0 4
-34 6 -75 4 -103 -6 -110 -2 -90 44 20 49 21 47 -17 37z m-299 -150 l21 -18
-35 -51 c-51 -75 -92 -116 -112 -112 -15 3 -18 14 -18 68 0 87 28 130 85 130
21 0 47 -8 59 -17z"/>
<path d="M1822 510 c-33 -20 -52 -54 -52 -92 0 -11 26 -47 59 -81 68 -72 82
-96 74 -126 -6 -25 -50 -51 -86 -51 -31 0 -77 36 -77 61 0 23 -15 25 -24 3
-18 -48 21 -94 81 -94 80 0 143 54 143 122 0 35 -7 47 -60 102 -47 49 -60 69
-60 94 0 56 79 84 118 41 16 -17 20 -18 25 -5 17 44 -81 62 -141 26z"/>
<path d="M7878 503 c-44 -23 -65 -53 -37 -53 17 0 68 39 79 61 6 10 9 18 8 18
-2 -1 -24 -13 -50 -26z"/>
<path d="M3636 511 c-3 -5 -18 -12 -33 -16 -41 -10 -60 -45 -25 -45 10 0 33
16 52 35 19 19 29 35 23 35 -7 0 -14 -4 -17 -9z"/>
<path d="M3785 479 c-16 -5 -45 -8 -63 -5 -27 4 -33 2 -28 -10 3 -9 6 -18 6
-20 0 -2 14 -4 31 -4 l32 0 -7 -46 c-10 -77 -121 -209 -169 -202 -25 3 -27 25
-12 136 l8 62 -27 0 c-24 0 -26 -4 -26 -40 0 -21 -5 -61 -11 -89 -12 -55 -3
-83 31 -101 29 -15 79 9 134 65 54 54 55 51 26 -79 -27 -124 -27 -146 5 -146
22 0 25 4 25 35 0 18 5 57 11 84 l11 50 42 -5 c123 -17 226 145 150 235 -30
36 -78 42 -121 14 l-26 -17 7 47 c4 26 6 47 4 46 -2 0 -16 -5 -33 -10z m141
-128 c25 -77 -24 -165 -91 -165 -57 0 -67 18 -53 94 13 81 42 113 97 108 31
-3 37 -8 47 -37z"/>
<path d="M9805 479 c-16 -5 -45 -8 -63 -5 -27 4 -33 2 -30 -12 2 -11 15 -18
37 -20 l34 -3 -7 -45 c-10 -77 -121 -209 -169 -202 -25 3 -26 22 -12 136 l8
62 -27 0 c-24 0 -26 -3 -26 -40 0 -21 -5 -61 -11 -89 -11 -54 -3 -83 29 -100
31 -17 73 1 128 54 30 30 54 48 54 42 0 -7 -9 -55 -20 -107 -27 -131 -26 -150
5 -150 22 0 25 4 25 35 0 18 5 57 11 84 l11 50 43 -6 c121 -16 224 147 149
236 -30 35 -67 40 -115 15 l-30 -15 6 45 c4 25 6 46 4 45 -2 0 -17 -5 -34 -10z
m141 -128 c25 -77 -24 -165 -91 -165 -60 0 -68 17 -50 104 9 41 17 75 18 75
23 17 49 24 77 22 30 -2 37 -7 46 -36z"/>
<path d="M4603 429 c-3 -12 -14 -19 -28 -19 -36 0 -89 -42 -112 -91 -39 -81
-143 -158 -158 -116 -3 6 1 48 9 92 15 87 14 95 -13 95 -33 0 -55 -196 -25
-220 29 -25 71 -15 123 28 27 23 52 42 54 42 3 0 8 -11 11 -24 8 -32 52 -59
97 -59 72 0 143 65 155 142 l6 36 59 0 c48 0 58 -3 55 -15 -3 -8 -8 -46 -12
-84 -5 -51 -3 -70 6 -76 17 -11 40 -1 40 16 0 43 114 217 138 212 19 -4 31
-54 31 -133 1 -58 22 -95 54 -95 26 0 102 80 140 148 43 79 16 69 -39 -15 -82
-125 -105 -122 -114 16 -5 95 -18 121 -56 121 -28 0 -83 -54 -115 -115 -13
-25 -26 -45 -29 -45 -3 0 -4 31 -2 70 3 66 2 70 -17 70 -14 0 -25 -9 -31 -25
-16 -43 -89 -32 -148 23 -41 39 -73 47 -79 21z m32 -53 c32 -14 35 -19 35 -56
0 -75 -45 -140 -98 -140 -35 0 -72 43 -72 83 0 40 35 96 70 114 31 16 24 16
65 -1z"/>
<path d="M6563 432 c-3 -13 -16 -22 -38 -26 -21 -4 -46 -21 -69 -46 -31 -34
-36 -47 -36 -89 0 -163 220 -146 260 21 11 44 15 49 34 44 11 -3 33 -6 47 -6
26 0 26 0 16 -49 -12 -62 -4 -96 28 -117 23 -15 27 -15 57 3 29 17 70 63 105
119 10 16 12 13 12 -26 2 -108 70 -120 153 -27 l38 42 -6 -47 c-9 -62 -1 -82
26 -68 11 6 20 18 20 27 0 21 44 97 88 152 31 39 38 43 68 38 36 -6 60 12 50
38 -17 45 -111 -14 -163 -103 -31 -53 -39 -43 -24 30 13 57 5 74 -28 63 -16
-5 -20 -12 -16 -26 13 -39 -95 -174 -133 -167 -31 6 -33 62 -6 186 6 30 5 32
-20 32 -17 0 -26 -5 -26 -15 0 -27 -59 -134 -96 -175 -46 -51 -84 -55 -84 -10
0 17 7 60 16 95 18 72 14 95 -15 95 -14 0 -21 -8 -23 -27 -2 -22 -9 -29 -32
-31 -44 -5 -73 5 -114 42 -49 44 -82 55 -89 28z m41 -54 c31 -14 40 -38 31
-86 -13 -70 -53 -112 -105 -112 -34 0 -60 36 -60 81 0 58 25 100 72 118 31 13
33 13 62 -1z"/>
<path d="M6366 418 c-3 -7 -7 -26 -11 -41 -8 -39 -66 -167 -75 -167 -4 0 -13
21 -20 47 -16 66 -50 143 -64 144 -14 2 -40 -2 -44 -6 -2 -1 10 -24 26 -49 17
-26 38 -76 48 -112 15 -56 15 -68 3 -90 -21 -40 -78 -74 -122 -74 -34 0 -38
-3 -35 -22 5 -37 59 -28 115 18 91 74 234 304 217 348 -7 19 -33 21 -38 4z"/>
<path d="M1266 198 c-7 -27 5 -41 30 -36 12 2 20 13 22 31 3 23 0 27 -21 27
-17 0 -26 -7 -31 -22z"/>
<path d="M1430 190 c0 -25 4 -30 24 -30 16 0 27 7 31 20 9 27 -1 40 -31 40
-20 0 -24 -5 -24 -30z"/>
<path d="M1590 189 c0 -27 3 -30 28 -27 21 2 28 9 30 31 3 24 0 27 -27 27 -28
0 -31 -3 -31 -31z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
